import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import Input from "components/Form/Input";
import PageTitle from "components/Title/Page";
import axios from "axios";
import _ from "lodash";
import SpinnerMedium from "components/Spinner/Medium";
import { infoService } from "common/services/info";
import ComboBox from "components/Form/Combobox";
import Button from "components/Form/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Textarea from "components/Form/Textarea";
import EmptyForm from "components/Form/Empty";
import SingleImageUploader from "components/Form/Input/Image/SingleUploader";
import { FileUtils } from "components/Utils/file";
import MultiBadgeCombo from "components/Form/MultiBadgeCombo";
import MultiImageUploader from "components/Form/Input/Image/MultiUploader";
import { StaffClient, staffClient } from "common/apis/staff";

const ReviewEditPage = () => {
    const { reviewId } = useParams();
    const [reviewInfo, setReviewInfo] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [selectedClinicInfo, setSelectedClinicInfo] = useState(null);
    const [selectedEventItem, setSelectedEventItem] = useState(null);
    const [selectedTreatmentItems, setSelectedTreatmentItems] = useState(null);
    const [selectedDoctorItems, setSelectedDoctorItems] = useState(null);
    const [formData, setFormData] = useState(null);

    const [isInitializing, setIsInitializing] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("[ReviewEditPage] useEffect");
        fetchReviewInfo();
    }, [])

    useEffect(() => {
        console.log("[ReviewEditPage] useEffect selectedClinicItem", selectedClinicItem);
        if (_.isNil(selectedClinicItem)) {
            return;
        }
        fetchSelectedClinicInfo();
    }, [selectedClinicItem])

    useEffect(() => {

    }, [selectedEventItem])

    useEffect(() => {
        console.log("[ReviewEditPage] useEffect selectedTreatmentItems", selectedTreatmentItems);
        if (_.isNil(selectedTreatmentItems)) {
            setFormData({
                ...formData,
                treatmentIds: null
            })
            return;
        }
        setFormData({
            ...formData,
            treatmentIds: selectedTreatmentItems.map((item) => item.id)
        })
    }, [selectedTreatmentItems])

    useEffect(() => {
        console.log("[ReviewEditPage] useEffect selectedDoctorItems", selectedDoctorItems);
        if (_.isNil(selectedDoctorItems)) {
            setFormData({
                ...formData,
                doctorIds: null
            })
            return;
        }
        setFormData({
            ...formData,
            doctorIds: selectedDoctorItems.map((item) => item.id)
        })
    }, [selectedDoctorItems])

    const fetchReviewInfo = async () => {
        try {
            // const ret = await axios.get(`https://rest.miview.shop/review/${reviewId}`);
            const ret = await staffClient.get(`/review/${reviewId}`);
            console.log("[ReviewEditPage] fetchReviewInfo ret", ret);
            const reviewData = ret.data;
            setReviewInfo(reviewData);
            setFormData({
                reviewId: reviewData.id,
                title: reviewData.title,
                content: reviewData.content,
                clinicId: reviewData.clinic?.id,
                eventId: reviewData.event?.id,
                treatmentIds: reviewData.treatments?.map((item) => item.id),
                doctorIds: reviewData.doctors?.map((item) => item.id),
                beforePhotos: reviewData.beforePhotos,
                afterPhotos: reviewData.afterPhotos,
                // mainImage: reviewData.mainImage,
                // cardImage: reviewData.cardImage,
                // detailImages: reviewData.detailImages,
            })
            const clinics = infoService.getClinics();
            const clinicItem = clinics.find((item) => item.id === reviewData.clinic?.id);
            console.log("[ReviewEditPage] fetchReviewInfo clinicItem", clinicItem);
            setSelectedClinicItem(clinicItem);
            setSelectedEventItem({
                id: reviewData.event?.id,
                name: reviewData.event?.title,
                ...reviewData.event
            });
            setSelectedTreatmentItems(reviewData.treatments);
            setSelectedDoctorItems(reviewData.doctors);
        } catch (e) {
            console.log("[ReviewEditPage] fetchReviewInfo error", e);
        }
    }

    const fetchSelectedClinicInfo = async () => {
        try {
            // const ret = await axios.get(`https://rest.miview.shop/clinic/${selectedClinicItem.id}`);
            const ret = await staffClient.get(`/clinic/${selectedClinicItem.id}`);
            console.log("[ReviewEditPage] fetchSelectedClinicInfo ret", ret);
            setSelectedClinicInfo(ret.data);
        } catch (e) {
            console.log("[ReviewEditPage] fetchSelectedClinicInfo error", e);
        }
    }

    const onSave = async () => {
        console.log("[ReviewEditPage] onSave", formData);
        try {
            // const ret = await axios.patch(`https://rest.miview.shop/review/${reviewId}`, formData);
            const ret = await staffClient.patch(`/review/${reviewId}`, formData);
            console.log("[ReviewEditPage] onSave ret", ret);
            toast.success("리뷰가 수정되었습니다.");
            navigate("/main/review");
        } catch (e) {
            console.log("[ReviewEditPage] onSave error", e);
            toast.error("리뷰 수정 중 오류가 발생했습니다.");
        }
    }

    const onMainImageChanged = async (files) => {
        console.log("[ReviewEditPage] onMainImageChanged", files);
        const uploadUrl = await StaffClient.getS3UploadUrl(files[0].name);
        const url = await FileUtils.uploadS3File(files[0], uploadUrl);
        console.log("[ReviewEditPage] onMainImageChanged url", url);
        setFormData({
            ...formData,
            mainImage: url
        })
    }

    const onCardImageChanged = async (files) => {
        console.log("[ReviewEditPage] onCardImageChanged", files);
        const uploadUrl = await StaffClient.getS3UploadUrl(files[0].name);
        const url = await FileUtils.uploadS3File(files[0], uploadUrl);
        console.log("[ReviewEditPage] onCardImageChanged url", url);
        setFormData({
            ...formData,
            cardImage: url
        })
    }

    const onBeforePhotosFilesChanged = async (files) => {
        // multiple
        console.log("[ClinicEditPage] onBeforePhotosFilesChanged", files);
        let urls = [];
        if (files.length > 0) {
            for await (const file of files) {
                const uploadUrl = await StaffClient.getS3UploadUrl(file.name);
                const url = await FileUtils.uploadS3File(file, uploadUrl);
                urls.push(url);
            }
        }
        console.log("[ClinicEditPage] onBeforePhotosFilesChanged urls", urls);
        setFormData({
            ...formData,
            beforePhotos: urls
        })
    }


    const onAfterPhotosFilesChanged = async (files) => {
        // multiple
        console.log("[ClinicEditPage] onAfterPhotosFilesChanged", files);
        let urls = [];
        if (files.length > 0) {
            for await (const file of files) {
                const uploadUrl = await StaffClient.getS3UploadUrl(file.name);
                const url = await FileUtils.uploadS3File(file, uploadUrl);
                urls.push(url);
            }
        }
        console.log("[ClinicEditPage] onBeforePhotosFileChanged urls", urls);
        setFormData({
            ...formData,
            afterPhotos: urls
        })
    }

    if (_.isNil(reviewInfo) || _.isNil(formData)) {
        return <SpinnerMedium />
    }

    console.log("Selected Event Item", selectedEventItem);

    return (
        <Card>
            <div className="p-3 flex flex-col space-y-2">
                <PageTitle>리뷰 수정</PageTitle>
                <div>
                    <div className="font-semibold">
                        리뷰 ID
                    </div>
                    <Input
                        value={reviewId}
                        disabled={true}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        의원명
                    </div>
                    <ComboBox
                        items={infoService.getClinics()}
                        selectedItem={selectedClinicItem}
                        onChange={(item) => {
                            setSelectedEventItem(null);
                            setSelectedTreatmentItems(null);
                            setSelectedDoctorItems(null);
                            setSelectedClinicItem(item);
                            setFormData({ ...formData, clinicId: item?.id });
                        }}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        이벤트명
                    </div>
                    <ComboBox
                        disabled={_.isNil(selectedClinicItem)}
                        items={selectedClinicInfo?.events.map((item) => ({ id: item.id, name: item.title, ...item }))}
                        selectedItem={selectedEventItem}
                        onChange={(item) => {
                            setSelectedTreatmentItems(null);
                            setSelectedEventItem(item);
                            setFormData({ ...formData, eventId: item?.id });
                        }}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        시술명
                    </div>
                    <EmptyForm>
                        <div className="p-2">
                            <MultiBadgeCombo
                                items={infoService.getTreatments().filter((item) => {
                                    if (_.isNil(selectedEventItem)) {
                                        return true;
                                    }
                                    const isIncluded = selectedEventItem?.treatments?.find((treatment) => treatment.id === item.id);
                                    return isIncluded;
                                })}
                                selectedItems={selectedTreatmentItems}
                                onSelectedItemsChange={setSelectedTreatmentItems}
                            />
                        </div>
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        의사명
                    </div>
                    <EmptyForm>
                        <div className="p-2">
                            <MultiBadgeCombo
                                items={selectedClinicInfo?.doctors}
                                selectedItems={selectedDoctorItems}
                                onSelectedItemsChange={setSelectedDoctorItems}
                            />
                        </div>
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        시술전 사진
                    </div>
                    <EmptyForm>
                        <MultiImageUploader
                            imgUrls={formData.beforePhotos}
                            onFileSelected={onBeforePhotosFilesChanged}
                            onChange={(urls) => {
                                console.log("[ReviewEditPage] onChange urls", urls);
                                setFormData({
                                    ...formData,
                                    beforePhotos: _.isEmpty(urls) ? null : urls
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        시술후 사진
                    </div>
                    <EmptyForm>
                        <MultiImageUploader
                            imgUrls={formData.afterPhotos}
                            onFileSelected={onAfterPhotosFilesChanged}
                            onChange={(urls) => {
                                console.log("[ReviewEditPage] onChange urls", urls);
                                setFormData({
                                    ...formData,
                                    afterPhotos: _.isEmpty(urls) ? null : urls
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        제목
                    </div>
                    <Input
                        value={formData.title}
                        onChange={(value) => setFormData({ ...formData, title: value })}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        내용
                    </div>
                    <Textarea
                        value={formData.content}
                        onChange={(value) => setFormData({ ...formData, content: value })}
                        resizable={true}
                    />
                </div>
                {/* <div>
                    <div className="font-semibold">
                        소속 의원
                    </div>
                    <ComboBox
                        items={clinicItems}
                        selectedItem={selectedClinicItem}
                        onChange={(item) => { setSelectedClinicItem(item); setFormData({ ...formData, clinicId: item?.id }) }}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        프로필 이미지
                    </div>
                    <EmptyForm>
                        <SingleImageUploader
                            imgUrl={formData.profileImage}
                            onFileSelected={onProfileImageFileSelected}
                            onChange={(url) => {
                                setFormData({
                                    ...formData,
                                    profileImage: url
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        진료과목
                    </div>
                    <EmptyForm>
                        <div className="p-2">
                            <MultiBadgeCombo
                                items={infoService.getSpecialties()}
                                selectedItems={formData?.specialtyIds?.map((id) => infoService.getSpecialtyById(id))}
                                setSelectedItems={(items) => {
                                    console.log("[ClinicEditPage] setSelectedItems", items);
                                    setFormData({
                                        ...formData,
                                        specialtyIds: items.map((item) => item.id)
                                    })
                                }}
                            />
                        </div>
                    </EmptyForm>
                </div> */}
                <div className="border-t border-gray-300"></div>
                <div>
                    <div className="flex justify-between space-x-2">
                        <div>
                            <Button
                                onButtonClicked={() => { navigate("/main/doctor") }}
                            >
                                목록
                            </Button>
                        </div>
                        <div className="flex space-x-2">
                            <div>
                                <Button
                                    onButtonClicked={() => {
                                        const tempReviewInfo = {
                                            ..._.omit(formData, ["clinicId", "eventId", "treatmentIds", "doctorIds"]),
                                            clinic: selectedClinicItem,
                                            event: selectedEventItem,
                                            treatments: formData.treatmentIds.map((id) => infoService.getTreatmentById(id)),
                                            doctors: formData.doctorIds.map((id) => selectedClinicInfo.doctors.find((item) => item.id === id)),
                                        }
                                        window.localStorage.setItem("tempReviewInfo", JSON.stringify(tempReviewInfo));
                                        window.open(`/preview/review/pv`, "_blank");
                                    }}
                                >
                                    미리보기
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onButtonClicked={onSave}
                                >
                                    저장
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ReviewEditPage;
