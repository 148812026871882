import Card from "components/Card";
import SpinnerMedium from "components/Spinner/Medium";
import SimpleTable from "components/Table/Simple";
import PageTitle from "components/Title/Page";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { columns } from "./config";
import Button from "components/Form/Button";
import { StaffClient } from "common/apis/staff";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MyPage = () => {
    const [myInfo, setMyInfo] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMyInfo();
    }, [])

    const fetchMyInfo = async () => {
        const ret = await StaffClient.getMyInfo();
        console.log("[fetchMyInfo] ret", ret);
        setMyInfo({
            name: ret.name,
            ...ret.token
        });
    }

    if (_.isNil(myInfo)) {
        return <SpinnerMedium />;
    }

    const onLogoutButtonClicked = async () => {
        try {
            await StaffClient.logout();
            navigate("/login");
            toast.success("로그아웃 되었습니다.");
        } catch (e) {
            console.error("[onLogoutButtonClicked] error", e);
            toast.error("로그아웃에 실패했습니다.");
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>마이페이지</PageTitle>
                <SimpleTable
                    data={[myInfo]}
                    columns={columns}
                />
                <div className="flex space-x-2 justify-end mt-1">
                    <div>
                        <Button onButtonClicked={() => { navigate("/password-reset") }}>비밀번호 재설정</Button>
                    </div>
                    <div>
                        <Button onButtonClicked={onLogoutButtonClicked}>로그아웃</Button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default MyPage;
