import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PublicSideNav from 'containers/SideNav/public';
import PublicSiteRoute from 'containers/SiteRoute/public';

import "./App.scss";
import { infoService } from 'common/services/info';
import SpinnerMedium from 'components/Spinner/Medium';
import SideNavWithContent from 'containers/Layout/SideNavWithContent';
import PreviewContainer from 'containers/Layout/Preview';
import LoginPage from 'pages/User/Login';
import PasswordResetPage from 'pages/User/PasswordReset';
import LineOAuthPage from 'common/pages/OAuth/Line';
import ApplyPage from 'pages/Test/Apply';
import ApplyFinishedPage from 'pages/Test/Apply/Finished';

function App() {
    return (
        <Routes>
            <Route path="/preview/*" element={<PreviewContainer></PreviewContainer>}></Route>
            <Route path="/login" element={<LoginPage></LoginPage>}></Route>
            <Route path="/password-reset" element={<PasswordResetPage></PasswordResetPage>}></Route>
            <Route path="/oauth/line" element={<LineOAuthPage />}></Route>
            <Route path="/form" element={<ApplyPage />}></Route>
            <Route path="/form/completed" element={<ApplyFinishedPage />}></Route>
            <Route path="/*" element={
                <SideNavWithContent></SideNavWithContent>
            }>
            </Route>
        </Routes>
    );
}

export default App;