import CenterLayout from "common/layouts/Center";
import PageTitle from "components/Title/Page";
import React from "react";

// 감사 문구 좀더 추가
const ApplyFinishedPage = () => {
    return (
        <CenterLayout>
            <div className="md:w-[300px] flex flex-col space-y-3">
                <div>
                    <PageTitle>신청 완료</PageTitle>
                </div>
                <div className="flex flex-col space-y-1">
                    <div>
                        미뷰 입점 신청이 완료되었습니다.
                    </div>
                    <div>
                        담당자가 확인 후 연락드리겠습니다.
                    </div>
                    <div>
                        감사합니다.
                    </div>
                </div>
            </div>
        </CenterLayout>
    )
}

export default ApplyFinishedPage;
