export const sourceItems = [
    {
        id: "0",
        name: "온라인 광고",
    },
    {
        id: "1",
        name: "지인 소개",
    },
    {
        id: "2",
        name: "전화영업",
    },
    {
        id: "3",
        name: "우편수령",
    },
    {
        id: "4",
        name: "잡지광고",
    },
    {
        id: "5",
        name: "미뷰 영업사원",
    },
]

export const serviceItems = [
    {
        id: "0",
        name: "강남언니",
    },
    {
        id: "1",
        name: "바비톡",
    },
    {
        id: "2",
        name: "여신티켓",
    },
    {
        id: "3",
        name: "굿닥",
    },
    {
        id: "4",
        name: "미인하이",
    },
]