import React, { useEffect, useState } from "react";
import axios from "axios";
import PagingTable, { EMPTY_PAGING_TABLE_RET } from "components/Table/Paging";
import Card from "components/Card";
import { columns } from "./config";
import _ from "lodash";
import PageTitle from "components/Title/Page";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassCircleIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Column } from "components/Table/Simple";
import { staffClient } from "common/apis/staff";

const BoardManager = () => {
    const navigate = useNavigate();
    const [refreshKey, setRefreshKey] = useState(_.uniqueId());

    const fetchData = async (page, limit, addditionalParams) => {
        try {
            const params = {
                offset: (page - 1) * limit,
                limit,
                ...addditionalParams
            }
            // const ret = await axios.get(`https://rest.miview.shop/board`, { params });
            const ret = await staffClient.get("/board", { params });
            console.log("[fetchData] ret", ret);
            return {
                data: ret.data?.data,
                total: ret.data?.meta?.total,
                page: page,
            }
        } catch (e) {
            console.error("[fetchData] error", e);
            return EMPTY_PAGING_TABLE_RET;
        }
    }

    const onCreated = async (row) => {
        try {
            // const ret = await axios.post(`https://rest.miview.shop/board`, row);
            const ret = await staffClient.post("/board", row);
            console.log("[onCreated] ret", ret);
            setRefreshKey(_.uniqueId());
            return ret.data;
        } catch (e) {
            console.error("[onCreated] error", e);
            return null;
        }
    }

    const onEdited = async (row) => {
        try {
            // const ret = await axios.patch(`https://rest.miview.shop/board/${row.id}`, row);
            const ret = await staffClient.patch(`/board/${row.id}`, row);
            console.log("[onEdited] ret", ret);
            setRefreshKey(_.uniqueId());
            return ret.data;
        } catch (e) {
            console.error("[onEdited] error", e);
            return null;
        }
    }

    const onDeleted = async (row) => {
        try {
            // const ret = await axios.delete(`https://rest.miview.shop/board/${row.id}`);
            const ret = await staffClient.delete(`/board/${row.id}`);
            console.log("[onDeleted] ret", ret);
            setRefreshKey(_.uniqueId());
            return ret.data;
        } catch (e) {
            console.error("[onDeleted] error", e);
            return null;
        }
    }

    const columnsWithActions: Column[] = [
        {
            field: "view",
            label: "보기",
            render: (value, field, row) => {
                return (
                    <div className="flex justify-center cursor-pointer"
                        onClick={() => { navigate(`/test/board/${row.id}`) }}>
                        <MagnifyingGlassIcon className="size-4" />
                    </div>
                )
            }
        },
        ...columns,
    ]

    return (
        <Card>
            <div className="p-3 flex flex-col space-y-2">
                <PageTitle>
                    게시판 관리
                </PageTitle>
                <PagingTable
                    refreshKey={refreshKey}
                    fetchData={fetchData}
                    columns={columnsWithActions}
                    isCrudable={true}
                    onCreate={onCreated}
                    onEdit={onEdited}
                    onDelete={onDeleted}
                />
            </div>
        </Card>
    )
}

export default BoardManager;
