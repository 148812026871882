import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DoctorCard } from "../ClinicDetail/ClinicDoctorList";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";
import DoctorInfo from "./DoctorInfo";
import SpinnerMedium from "components/Spinner/Medium";
import { StarIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import DoctorReviewList from "./DoctorRevieList";
import TopNav from "common/components/TobNav";
import { staffClient } from "common/apis/staff";
import { UrlUtils } from "components/Utils/url";

export const DoctorProfile = ({ doctor }) => {
    return (
        <div className="group rounded-md overflow-hidden">
            <div className="flex items-center">
                {!_.isEmpty(doctor.profileImage) ?
                    <img
                        src={UrlUtils.convertCdnUrl(doctor.profileImage)}
                        alt="profileImage"
                        className="h-24 w-24 p-2 object-center group-hover:opacity-75 rounded-full"
                    />
                    :
                    <div className="flex justify-center text-pink-300">
                        <UserCircleIcon className="size-24" />
                    </div>
                }
                <div className="p-2 flex flex-col space-y-2">
                    <div>
                        <h1 className="text-lg font-bold">
                            {`${doctor.name} 원장`}
                        </h1>
                    </div>
                    <div className="flex items-center">
                        <StarIcon className="w-4 h-4 text-yellow-400" />
                        <span className="text-gray-500 text-xs">
                            {'5.0'}
                        </span>
                        {/* <span className="ms-[2px] text-gray-600 text-xs">
                                {`口コミ${10}件症例${50}件`}
                            </span> */}
                    </div>
                    {/* <div className="flex items-center">
                            <span className="text-pink-700 text-xs">
                                {`¥${numeral(price).format("0,0")}`}
                            </span>
                            <span className="text-gray-600 text-[10px] ps-1">
                                {` | 通常価格：¥${numeral(originPrice).format("0,0")}`}
                            </span>
                        </div> */}
                    {/* <div>
                        <Badges
                            items={doctor.specialties.map((sp) => sp.name)}
                            isRemovable={false}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const DoctorDetail = () => {
    const { doctorId } = useParams();
    const [doctorInfo, setDoctorInfo] = useState(null);

    useEffect(() => {
        if (doctorId === "pv") {
            console.log("[DoctorDetail] doctorId is empty it's maybe preview page");
            console.log("[DoctorDetail] try get from localstorage");
            const tempDoctorInfoStr = localStorage.getItem("tempDoctorInfo");
            if (_.isNil(tempDoctorInfoStr)) {
                console.log("[DoctorDetail] tempDoctorInfo is empty");
                return;
            }
            const tempDoctorInfo = JSON.parse(tempDoctorInfoStr);
            console.log("[DoctorDetail] tempDoctorInfo", tempDoctorInfo);
            setDoctorInfo(tempDoctorInfo);
        }
        fetchDoctorInfo();
    }, [doctorId]);

    const fetchDoctorInfo = async () => {
        if (_.isNil(doctorId)) {
            return;
        }
        try {
            // const ret = await axios.get(`https://rest.miview.shop/doctor/${doctorId}`);
            const ret = await staffClient.get(`/doctor/${doctorId}`);
            console.log("[fetchDoctorInfo] ret", ret.data);
            setDoctorInfo(ret.data);
        } catch (e) {
            console.error("[fetchDoctorInfo] error", e);
        }
    }

    console.log("[DoctorDetail] doctorInfo", doctorInfo);

    if (_.isNil(doctorInfo)) {
        return <SpinnerMedium />;
    }

    return (
        <div>
            <TopNav
                pages={[
                    {
                        name: "탐색",
                        path: "/contents"
                    },
                    {
                        name: "의사",
                        path: "/contents?tabId=clinics"
                    },
                    {
                        name: doctorInfo.name
                    }
                ]}
                disablePlus={true}
            />
            <div className="pt-3">
                <DoctorProfile doctor={doctorInfo} />
                <div className="border-t border-gray-200"></div>
                <Tabs tabsClassnames='sticky top-0 bg-white' isEvenFullWidth={true}>
                    <Tab title='의사정보' path="info">
                        <DoctorInfo doctorInfo={doctorInfo} />
                    </Tab>
                    <Tab title='시술후기' path="review">
                        <DoctorReviewList doctorInfo={doctorInfo} />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default DoctorDetail;
