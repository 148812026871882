import { Column } from "components/Table/Simple";

export const columns: Column[] = [
    {
        field: "id",
        label: "고유번호",
    },
    {
        field: "user_id",
        label: "아이디",
        creatable: true,
    },
    {
        field: "name",
        label: "이름",
        creatable: true,
        editable: true,
    },
    {
        field: "user_type",
        label: "회원 유형",
        creatable: true,
        editable: true,
        selectable: {
            getOptions: () => {
                return [
                    { name: "직원", value: "STAFF" },
                    { name: "관리자", value: "MASTER" },
                ]
            }
        }
    },
    {
        field: "password_reset_required",
        label: "비밀번호 재설정 필요",
        render: (value) => value ? "○" : "✕",
        editable: true,
        selectable: {
            getOptions: () => {
                return [
                    { name: "○", value: true },
                    { name: "✕", value: false },
                ]
            }
        }
    }
]