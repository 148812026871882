import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { classNames } from "components/Utils/ui";

export default ({ to, children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <li>
            <label onClick={() => { navigate(to) }} className={classNames(`hover:bg-gray-50 block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700`, location.pathname.startsWith(to) && "bg-indigo-100")} htmlFor="toggle-sidenav">{children}</label>
        </li>
    )
}