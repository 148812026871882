
import React from "react";
import { Routes, Route } from "react-router-dom";

import ImageUploadTest from "pages/Test/UploadImage";
import FileUploadTest from "pages/Test/UploadFile";
import AddressSearch from "pages/Test/AddressSearch";
import TestForms from "pages/Test/Forms";
import BoardManager from "pages/Test/Board/BoardManager";
import BoardViewer from "pages/Test/Board/BoardViewer";
import ArticleViewer from "pages/Test/Board/ArticleViewer";
import ArticleEditor from "pages/Test/Board/ArticleEditor";
import OauthPage from "pages/Test/OAuth";

export default () => (
    <Routes>
        <Route path="/img-upload" element={<ImageUploadTest />}></Route>
        <Route path="/file-upload" element={<FileUploadTest />}></Route>
        <Route path="/address-search" element={<AddressSearch />}></Route>
        <Route path="/forms" element={<TestForms />}></Route>
        <Route path="/board" element={<BoardManager />}></Route>
        <Route path="/board/:boardId" element={<BoardViewer />}></Route>
        <Route path="/article/:articleId" element={<ArticleViewer />}></Route>
        <Route path="/article/editor/:articleId" element={<ArticleEditor />}></Route>
        <Route path="/oauth" element={<OauthPage />}></Route>
    </Routes>
)

