import React, { useState } from "react";
import CenterLayout from "common/layouts/Center";
import PageTitle from "components/Title/Page";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { StaffClient } from "common/apis/staff";


const LoginPage = () => {
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const onLoginButtonClicked = () => {
        requestLogin();
    }

    const requestLogin = async () => {
        try {
            const ret = await StaffClient.login(id, password);
            toast.success("로그인에 성공했습니다.");
            if (ret.password_reset_required) {
                navigate("/password-reset");
            }else {
                navigate("/");
            }
            return ret;
        } catch (e) {
            console.error("[onLoginButtonClicked] error", e);
            toast.error("로그인에 실패했습니다.");
            return null;
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            onLoginButtonClicked();
        }
    }

    return (
        <CenterLayout>
            <div className="md:w-[300px] flex flex-col space-y-3" onKeyDown={handleKeyDown} >
                <PageTitle>로그인</PageTitle>
                <div>
                    <Input label={"아이디"} value={id} onChange={(v) => { setId(v?.toLowerCase()) }}></Input>
                </div>
                <div>
                    <Input label={"비밀번호"} value={password} onChange={setPassword} type="password"></Input>
                </div>
                <div>
                    <Button onButtonClicked={onLoginButtonClicked}>{"로그인"}</Button>
                </div>
            </div>
        </CenterLayout>
    )
}

export default LoginPage;


