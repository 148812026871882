import { classNames } from 'components/Utils/ui'
import React from 'react'
import InputUpperLabel from '../Input/Label/InputUpper'

export default ({ upperLabel = "", placeholder = "", value, onChange = (text) => { }, rows = null, resizable = false, disabled = false, readOnly=false }) => {
    return (
        <div>
            {upperLabel && <InputUpperLabel>{upperLabel}</InputUpperLabel>}
            <div className="">
                <textarea
                    rows={rows ?? value?.split('\n')?.length ?? 1}
                    name="comment"
                    id="comment"
                    className={classNames("block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        resizable ? "" : "resize-none",
                        disabled ? "bg-gray-100" : ""
                    )}
                    onChange={(e) => { onChange(e.target.value) }}
                    placeholder={placeholder}
                    value={value ?? ""}
                    disabled={disabled}
                    readOnly={readOnly}
                />
            </div>
        </div>
    )
}
