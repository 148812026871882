import React, { useRef } from "react"
import { classNames } from "components/Utils/ui"
import { validateIdInput, validateNumberInput, validatePhoneInput } from "./validators"
import { toast } from "react-toastify"
import InputUpperLabel from "./Label/InputUpper"

import 'react-toastify/dist/ReactToastify.css';

export enum InputFormat {
    NUMBER = "NUMBER",
    PHONE = "PHONE",
    ID = "ID"
}

const Input = ({
    value = "",
    upperLabel = "",
    label = "",
    placeholder = "",
    format = "" as InputFormat,
    onChange = (value) => { },
    disabled = false,
    withButton = false,
    buttonLabel = "",
    onButtonClicked = (value) => { },
    ...otherProps
}) => {
    const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue === "") {
            onChange(newValue)
        }
        if (format === InputFormat.NUMBER) {
            if (validateNumberInput(newValue)) { // 값이 유효하거나 빈 문자열인 경우에만 변경 처리
                onChange(newValue);
            } else {
                toast.warn("숫자만 입력 가능합니다")
            }
        }
        else if (format === InputFormat.PHONE) {
            if (validatePhoneInput(newValue)) {
                onChange(newValue);
            } else {
                toast.warn("전화번호는 숫자만 입력 가능합니다")
            }
        }
        else if (format === InputFormat.ID) {
            if (validateIdInput(newValue)) {
                onChange(String(newValue).toLowerCase());
            } else {
                toast.warn("아이디는 영문, 숫자, ., _ 만 입력 가능합니다")
            }
        }
        else {
            onChange(newValue);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && withButton) {
            onButtonClicked(value);
        }
    };


    return (
        <div className="" onKeyDown={handleKeyDown}>
            {upperLabel && <InputUpperLabel>{upperLabel}</InputUpperLabel>}
            <div className="relative flex w-full">
                {label && <label
                    className={classNames("absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900")}
                >
                    {label}
                </label>}
                <input
                    className={classNames(
                        "block w-full border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white",
                        withButton ? "rounded-l-md" : "rounded-md",
                    )}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                    {...otherProps}
                />
                {withButton &&
                    <button
                        type="button"
                        className="-ml-px px-3 py-1.5 items-center rounded-r-md sm:text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-nowrap w-fit"
                        onClick={() => { onButtonClicked(value) }}
                    >
                        <div className="text-nowrap w-fit">
                            {buttonLabel}
                        </div>
                    </button>
                }
            </div>
        </div>
    )
}

export default Input