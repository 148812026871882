import { FileUtils } from "components/Utils/file";
import Card from "components/Card";
import Button from "components/Form/Button";
import MultiFileUploader from "components/Form/Input/File/MultiUploader";
import PageTitle from "components/Title/Page";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { StaffClient } from "common/apis/staff";

const FileUploadTest = () => {
    const [files, setFiles] = useState([]);

    const onFilesSelected = (files) => {
        console.log("[onFilesSelected] files", files);
        const tempFiles = [...files];
        setFiles(tempFiles);
    }

    const onUploadButtonClicked = async () => {
        console.log("[onUploadButtonClicked] files", files);
        for (const file of files) {
            // const ret = await uploadS3File(file);
            const url = await StaffClient.getS3UploadUrl(file);
            const ret = await FileUtils.uploadS3File(file, url);
            console.log("[onUploadButtonClicked] ret", ret);
        }
        toast.success(`파일 ${files.length}개 업로드 완료`);
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>파일 업로드 테스트</PageTitle>
                <MultiFileUploader files={files} onFilesSelected={onFilesSelected} />
                <div className="flex justify-end pt-2">
                    <div>
                        <Button onButtonClicked={onUploadButtonClicked}>업로드</Button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default FileUploadTest;
