import { DocumentTextIcon } from '@heroicons/react/20/solid';
import Card from 'components/Card';
import React, { useState } from 'react';
import { classNames, selectFiles } from 'components/Utils/ui'
import _ from 'lodash';
import Button from 'components/Form/Button';
import SimpleTable, { Column } from 'components/Table/Simple';
import { columns } from './config';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FileUtils } from 'components/Utils/file';

const checkFileSize = (file, maxFileSizeMb) => {
    console.log('[checkFileSize] file size', file.size, maxFileSizeMb);
    const maxFileSize = maxFileSizeMb * 1024 * 1024;
    if (file.size > maxFileSize) {
        alert(`${file.name} 파일의 크기가 ${maxFileSizeMb}MB를 초과했습니다.`);
        return false;
    }
    return true;
};

const MultiFileUploader = ({ files = [], onFilesSelected = (files) => {}, maxFileSizeMb = 100 }) => {
    const [isDragging, setIsDragging] = useState(false);

    const onNewFiles = async (newFiles) => {
        for (const file of newFiles) {
            if (!checkFileSize(file, maxFileSizeMb)) {
                return;
            }
            const hash = await FileUtils.getFileHash(file);
            file.hash = hash;
        }
        onFilesSelected(newFiles);
    };

    const handleClick = async () => {
        const selectedFiles = (await selectFiles()) as any[];
        console.log('[handleClick] selectedFiles', selectedFiles);
        if (!_.isArray(selectedFiles)) {
            return;
        }
        const newFiles = [...files, ...selectedFiles];
        onNewFiles(newFiles);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFiles = Array.from(event.dataTransfer.files);
        console.log('[handleDrop] droppedFiles', droppedFiles);
        const newFiles = [...files, ...droppedFiles];
        onNewFiles(newFiles);
    };

    const handleFilesTableClicked = (e) => {
        console.log('[handleFilesTableClicked] e', e);
        e.stopPropagation();
        e.preventDefault();
    };

    console.log('[MultiFileUploader] files', files);

    const tableData = files.map((file, index) => {
        return {
            name: file.name,
            // type: file.type,
            size: file.size,
        };
    });

    const columnsWithRemove: Column[] = [
        ...columns,
        {
            field: 'remove',
            label: '삭제',
            render: (value, row, index) => {
                return (
                    <div
                        onClick={() => {
                            console.log('[columnsWithRemove] index', index);
                            const newFiles = [...files];
                            newFiles.splice(index, 1);
                            onFilesSelected(newFiles);
                        }}
                    >
                        <XMarkIcon className="size-5 cursor-pointer" />
                    </div>
                );
            },
            align: 'center',
        },
    ];

    return (
        <div className="">
            <div className="flex mb-2">
                <div>
                    <Button onButtonClicked={handleClick}>파일 추가</Button>
                </div>
            </div>
            <div className={classNames('border cursor-pointer hover:bg-gray-50', isDragging ? 'bg-gray-50' : '')} onClick={handleClick} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                <div className="w-full">
                    <div>
                        {_.isEmpty(files) ? (
                            <div className="flex space-x-2 items-center justify-center w-full py-3">
                                <DocumentTextIcon className="size-12 text-indigo-600" />
                                <div className="text-gray-600">파일을 마우스로 끌어 오세요</div>
                            </div>
                        ) : (
                            <div onClick={handleFilesTableClicked}>
                                <SimpleTable data={tableData} columns={columnsWithRemove} disableSort={true} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiFileUploader;
