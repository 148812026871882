import axios from "axios";
import CryptoJS from "crypto-js";

export const staffClient = axios.create({
    baseURL: "https://rest.miview.shop",
    // baseURL: "http://localhost:8101",
    withCredentials: true,
});

staffClient.interceptors.response.use(
    response => {
        return response;
    },
    responseErrorInterceptor
);

export async function responseErrorInterceptor(error) {
    console.log("[staffClient] response error", error);
    if (error.response.status === 401 || error.response.status === 500) {
        return handle401Error(error);
    }
    return Promise.reject(error);
}

const handle401Error = async (error) => {
    console.log("[401 error]", error);
    window.location.replace("/login");
    return Promise.reject(error);
}

export namespace StaffClient {
    export const login = async (id: string, password) => {
        try {
            const shadPwd = CryptoJS.SHA256(password).toString();
            const body = {
                user_id: id,
                password: shadPwd
            }
            const ret = await staffClient.post(`/auth/login`, body, { withCredentials: true });
            console.log("[login] ret", ret);
            const { token } = ret.data;
            // tryAssignDevAuthCookie(token);
            return ret.data;
        } catch (e) {
            console.error("[login] error", e);
            throw e;
        }
    }

    export const resetPassword = async (id: string, password) => {
        const shadPwd = CryptoJS.SHA256(password).toString();
        try {
            const body = {
                user_id: id,
                new_password: shadPwd
            }
            const ret = await staffClient.post(`/auth/reset-password`, body);
            console.log("[handleClick] ret", ret);
            return ret.data;
        } catch (e) {
            console.error("[handleClick] error", e);
            throw e;
        }
    }

    export const logout = async () => {
        try {
            const ret = await staffClient.post(`/auth/logout`);
            console.log("[logout] ret", ret);
            return ret.data;
        } catch (e) {
            console.error("[logout] error", e);
            throw e;
        }
    }

    export const getMyInfo = async () => {
        try {
            const ret = await staffClient.get(`/auth/my-info`);
            console.log("[getMyInfo] ret", ret);
            return ret.data;
        } catch (e) {
            console.error("[getMyInfo] error", e);
            throw e;
        }
    }

    export const getS3UploadUrl = async (file) => {
        try {
            const response = await staffClient.get(`/s3/generate-s3-url`, {
                params: {
                    filename: file.name,
                    filetype: file.type
                }
            });

            if (response.status !== 200) {
                console.error('Failed to get signed URL.');
                return;
            }
            console.log("[ret]", response)
            const { url } = response.data;
            return url;
        } catch (e) {
            console.error("[getS3UploadUrl] error", e);
            throw e;
        }
    }
}
