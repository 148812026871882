import React from "react";
import AppLayout from "common/layouts/AppLayout";
import PreviewRoutes from "containers/SiteRoute/Preview";

const PreviewContainer = () => {
    return (
        <AppLayout>
            <PreviewRoutes></PreviewRoutes>
        </AppLayout>
    )
}

export default PreviewContainer;


