import { Checkbox, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from 'components/Utils/ui'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import SpinnerMedium from 'components/Spinner/Medium'
import InputUpperLabel from '../Input/Label/InputUpper'
import { getSha10length } from 'components/Utils/ui'

const EXAMPLE_ITEMS: { id?: number, name: string }[] = [
    { id: 1, name: 'Durward Reynolds' },
    { id: 2, name: 'Kenton Towne' },
    { id: 3, name: 'Therese Wunsch' },
    { id: 4, name: 'Benedict Kessler' },
    { id: 5, name: 'Katelyn Rohan' },
]

const MultiSelect = ({ upperLabel = "", label = "Select", items = EXAMPLE_ITEMS, onChange = (itemNames) => { }, enableSearch = true, enableSelectAll = true, saveSelectedItems = false }) => {
    const [selectedPerson, setSelectedPerson] = useState(items[0]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(true);
    const [uid, setUid] = useState("");

    const onOptionClicked = (e, person) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedItems({ ...selectedItems, [person.name]: !selectedItems[person.name] });
    }

    useEffect(() => {
    }, [])

    useEffect(() => {
        console.log("[MultiSelect] useEffect items", items);
        const names = items.map((item) => item.name);
        const stringNames = JSON.stringify(names);
        const encodedNames = getSha10length(stringNames);
        setUid(encodedNames);
        if (saveSelectedItems) {
            const saved = localStorage.getItem(encodedNames);
            if (saved) {
                setSelectedItems(JSON.parse(saved));
                return;
            }
        }
        const tmp = items.reduce((acc, person) => {
            acc[person.name] = isAllSelected;
            return acc;
        }, {})
        setSelectedItems(tmp);
    }, [items])

    useEffect(() => {
        console.log("[MultiSelect] useEffect selectedItems", selectedItems);
        if (_.isNil(selectedItems)) {
            return;
        }
        onChange(Object.keys(selectedItems).filter((key) => selectedItems[key]));
        setIsAllSelected(Object.keys(selectedItems).filter((key) => selectedItems[key]).length === items.length);
        if (saveSelectedItems) {
            if (uid === "") return;
            localStorage.setItem(uid, JSON.stringify(selectedItems));
        }
    }, [selectedItems])

    const onAllSelectClicked = () => {
        const temp = {};
        items.forEach((person) => {
            temp[person.name] = !isAllSelected;
        })
        setSelectedItems(temp);
        setIsAllSelected(!isAllSelected);
    }

    if (_.isNil(selectedItems)) return (<SpinnerMedium />);

    return (
        <>
            {upperLabel && <InputUpperLabel>{upperLabel}</InputUpperLabel>}
            <Listbox value={selectedPerson} onChange={setSelectedPerson}>
                <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6 hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 text-nowrap">
                    {`${label}(${Object.keys(selectedItems).filter((key) => selectedItems[key]).length})`}
                    <div className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </ListboxButton>
                <Transition leave="transition ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <ListboxOptions
                        anchor="bottom start"
                        className={"z-[200] w-fit rounded-md border ring-1 ring-black ring-opacity-5 bg-white [--anchor-gap:4px] focus:outline-none sm:text-sm"}
                    >
                        {enableSearch && (
                            <input
                                className="w-fit mx-2 mt-2 mb-1 rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder='Search...'
                                onChange={(event) => setSearchQuery(event.target.value)}
                            />
                        )}
                        {enableSelectAll && (
                            <div>
                                <div
                                    className="flex items-center align-middle px-3 py-2 hover:bg-indigo-600 hover:text-white cursor-pointer"
                                    onClick={onAllSelectClicked}
                                >
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        checked={isAllSelected}
                                        readOnly={true}
                                    />
                                    <div className="ms-2">
                                        {"전체 선택"}
                                    </div>
                                </div>
                                <div className="flex-grow border-b border-gray-300 mb-1"></div>
                            </div>
                        )}
                        {items.filter((item) => item.name.includes(searchQuery)).map((item, idx) => (
                            _.isNil(item) ? <hr key={idx} className="border-t-1 border-gray-200" /> :
                                <ListboxOption
                                    key={idx}
                                    value={item}
                                    className={classNames("group relative cursor-default select-none py-2 px-3 text-gray-900",
                                        "hover:bg-indigo-600 hover:text-white cursor-pointer"
                                    )}
                                    onClick={(e) => { onOptionClicked(e, item) }}
                                >
                                    <div className="flex items-center align-middle">
                                        <input
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            checked={selectedItems[item.name] || false}
                                            readOnly={true}
                                        />
                                        <div className="ms-2 text-nowrap">
                                            {item.name}
                                        </div>
                                    </div>
                                </ListboxOption>
                        ))}
                    </ListboxOptions>
                </Transition>
            </Listbox>
        </>
    )
}

export default MultiSelect;