import { Column } from "components/Table/Simple";
import { formatDateTime } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "id",
        label: "ID",
    },
    {
        field: "name",
        label: "사용자명",
    },
    {
        field: "type",
        label: "사용자 유형",
    },
    {
        field: "iat",
        label: "로그인 시간",
        render: (value) => {
            const timestamp = new Date(value * 1000);
            const formatted = timestamp.toISOString();
            return formatDateTime(formatted)
        },
    },
    {
        field: "exp",
        label: "로그인 만료 시간",
        render: (value) => {
            const timestamp = new Date(value * 1000);
            const formatted = timestamp.toISOString();
            return formatDateTime(formatted)
        },
    },
]