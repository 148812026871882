import React, { useState } from "react";
import CenterLayout from "common/layouts/Center";
import PageTitle from "components/Title/Page";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { toast } from "react-toastify";
import { StaffClient } from "common/apis/staff";
import { useNavigate } from "react-router-dom";

const PasswordResetPage = () => {
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const navigate = useNavigate();


    const handleClick = async () => {
        const ret = await StaffClient.getMyInfo();
        const userId = ret.token.id;
        if (password !== rePassword) {
            toast.error("비밀번호가 일치하지 않습니다.");
            return;
        }
        try {
            const ret = await StaffClient.resetPassword(userId, password);
            console.log("[handleClick] ret", ret);
            toast.success("비밀번호가 변경되었습니다.");
            navigate("/");
        } catch (e) {
            console.error("[handleClick] error", e);
            toast.error("비밀번호 변경에 실패했습니다.");
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleClick();
        }
    }
    return (
        <CenterLayout>
            <div onKeyDown={handleKeyDown}>
                <div className="md:w-[300px] flex flex-col space-y-3">
                    <PageTitle>비밀번호 재설정</PageTitle>
                    <div >
                        <Input label={"새 비밀번호"} value={password} onChange={setPassword} type="password"></Input>
                    </div>
                    <div >
                        <Input label={"비밀번호 확인"} value={rePassword} onChange={setRePassword} type="password"></Input>
                    </div>
                    <div >
                        <Button onButtonClicked={handleClick}>확인</Button>
                    </div>
                </div>
            </div>
        </CenterLayout>
    )
}

export default PasswordResetPage;


