import { StarIcon } from "@heroicons/react/20/solid";
import { UrlUtils } from "components/Utils/url";
import Badges from "components/Badges";
import Card from "components/Card";
import SpinnerMedium from "components/Spinner/Medium";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const EventCard = ({ event }) => {
    const navigate = useNavigate();

    return (
        <div className="cursor-pointer" onClick={() => {
            navigate(`/contents/event/${event.id}`);
        }}>
            <div className="group rounded-md overflow-hidden">
                <div className="flex items-center">
                    <img
                        src={UrlUtils.convertCdnUrl(event.mainImage)}
                        alt="profileImage"
                        className="h-24 w-24 p-2 object-center group-hover:opacity-75"
                    />
                    <div className="p-2 flex flex-col space-y-2 overflow-hidden">
                        <div>
                            <h1 className="text-lg font-bold">
                                {`${event.title}`}
                            </h1>
                        </div>
                        <div className="flex items-center">
                            <StarIcon className="w-4 h-4 text-yellow-400" />
                            <span className="text-gray-500 text-xs">
                                {'5.0'}
                            </span>
                            {/* <span className="ms-[2px] text-gray-600 text-xs">
                                {`口コミ${10}件症例${50}件`}
                            </span> */}
                        </div>
                        <div className="overflow-auto">
                            <Badges
                                items={event.treatments.map((item) => item.name)}
                                isRemovable={false}
                            />
                        </div>
                        {/* <div className="flex items-center">
                            <span className="text-pink-700 text-xs">
                                {`¥${numeral(price).format("0,0")}`}
                            </span>
                            <span className="text-gray-600 text-[10px] ps-1">
                                {` | 通常価格：¥${numeral(originPrice).format("0,0")}`}
                            </span>
                        </div> */}
                        {/* <div>
                            <Badges
                                items={doctor.specialties.map((sp) => sp.name)}
                                isRemovable={false}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClinicEventList = ({ clinicInfo }) => {
    const [events, setEvents] = useState(null);

    useEffect(() => {
        if (_.isNil(clinicInfo.events)) {
            console.log("[ClinicEventList] clinicInfo.events is empty");
            return;
        }
        setEvents(clinicInfo.events);
    }, [clinicInfo])

    if (_.isNil(events)) {
        return <SpinnerMedium />
    }
    console.log("[ClinicEventList] events", events);

    if (_.isEmpty(events)) {
        return (
            <div>
                <div className="text-center text-gray-500 py-10">
                    진행중인 이벤트가 없습니다
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col space-y-2 mt-2">
            {events.map((event, idx) => (
                <EventCard key={idx} event={event} />
            ))}
        </div>
    )
}

export default ClinicEventList;
