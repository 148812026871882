import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { classNames } from 'components/Utils/ui'
import { useSearchParams } from 'react-router-dom'

export default ({ children, tabsClassnames = "", isEvenFullWidth = false, linkSearchParam = true }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [tabId, setTabId] = useState(null);

    useEffect(() => {
        if (!linkSearchParam) {
            return;
        }
        console.log("searchParam", searchParams);
        const tempTabId = searchParams.get("tabId");
        console.log("tempTabId", tempTabId)
        if (!_.isNil(tempTabId)) {
            setTabId(tempTabId);
            return;
        }
        if (!_.isArray(children)) children = [children];
        if (!_.isNil(children[0])) {
            console.log("navigate to default", children[0].props);
            console.log("children[0].props.path", children[0].props.path)
            const path = children[0].props.path;
            // not use because back button bug
            // setSearchParams(new URLSearchParams({ ...Object.fromEntries(searchParams), tabId: path }));
        }
    }, [searchParams])

    const childrenProps = children.map((child) => child.props)
    let currentTab = _.find(children, (elm) => elm.props.path === tabId) ?? children[0];

    return (
        <div>
            <div className={classNames("overflow-scroll border-b border-gray-200", tabsClassnames)}>
                <nav className="flex" aria-label="Tabs">
                    {childrenProps.map((tab) => (
                        <label
                            key={tab.title}
                            className={classNames(
                                tab.path === currentTab.props.path
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 py-3 px-5 text-sm font-medium',
                                isEvenFullWidth ? 'w-full flex justify-center' : ""
                            )}
                            onClick={(() => {
                                if (!linkSearchParam) {
                                    setTabId(tab.path);
                                    return;
                                }
                                setSearchParams(new URLSearchParams({ ...Object.fromEntries(searchParams), tabId: tab.path }))
                            })}
                        >
                            {tab.title}
                        </label>
                    ))}
                </nav>
            </div>
            {currentTab}
        </div>
    )
}
