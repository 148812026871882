import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import Input from "components/Form/Input";
import PageTitle from "components/Title/Page";
import axios from "axios";
import _ from "lodash";
import SpinnerMedium from "components/Spinner/Medium";
import { infoService } from "common/services/info";
import ComboBox from "components/Form/Combobox";
import Button from "components/Form/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Textarea from "components/Form/Textarea";
import EmptyForm from "components/Form/Empty";
import SingleImageUploader from "components/Form/Input/Image/SingleUploader";
import { FileUtils } from "components/Utils/file";
import MultiBadgeCombo from "components/Form/MultiBadgeCombo";
import MultiImageUploader from "components/Form/Input/Image/MultiUploader";
import { StaffClient, staffClient } from "common/apis/staff";

const EventEditPage = () => {
    const { eventId } = useParams();
    const [eventInfo, setEventInfo] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [formData, setFormData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("[EventEditPage] useEffect");
        fetchEventInfo();
    }, [])

    const fetchEventInfo = async () => {
        try {
            // const ret = await axios.get(`https://rest.miview.shop/event/${eventId}`);
            const ret = await staffClient.get(`/event/${eventId}`);
            console.log("[EventEditPage] fetchDoctorInfo ret", ret);
            const eventData = ret.data;
            setEventInfo(eventData);
            setFormData({
                eventId: eventData.id,
                title: eventData.title,
                content: eventData.content,
                clinicId: eventData.clinic?.id,
                treatmentIds: eventData.treatments?.map((item) => item.id),
                mainImage: eventData.mainImage,
                cardImage: eventData.cardImage,
                detailImages: eventData.detailImages ?? [],
            })
            const clinics = infoService.getClinics();
            const clinicItem = clinics.find((item) => item.id === eventData.clinic?.id);
            console.log("[EventEditPage] fetchEventInfo clinicItem", clinicItem);
            setSelectedClinicItem(clinicItem);
        } catch (e) {
            console.log("[EventEditPage] fetchEventInfo error", e);
        }
    }

    const onSave = async () => {
        console.log("[EventEditPage] onSave", formData);
        try {
            // const ret = await axios.patch(`https://rest.miview.shop/event/${eventId}`, formData);
            const ret = await staffClient.patch(`/event/${eventId}`, formData);
            console.log("[EventEditPage] onSave ret", ret);
            toast.success("이벤트 정보가 수정되었습니다.");
            navigate("/main/event");
        } catch (e) {
            console.log("[EventEditPage] onSave error", e);
            toast.error("이벤트 정보 수정 중 오류가 발생했습니다.");
        }
    }

    const onMainImageChanged = async (files) => {
        console.log("[EventEditPage] onMainImageChanged", files);
        const uploadUrl = await StaffClient.getS3UploadUrl(files[0]);
        const fileUrl = await FileUtils.uploadS3File(files[0], uploadUrl);
        console.log("[EventEditPage] onMainImageChanged url", fileUrl);
        setFormData({
            ...formData,
            mainImage: fileUrl
        })
    }

    const onCardImageChanged = async (files) => {
        console.log("[EventEditPage] onCardImageChanged", files);
        const uploadUrl = await StaffClient.getS3UploadUrl(files[0]);
        const fileUrl = await FileUtils.uploadS3File(files[0], uploadUrl);
        console.log("[EventEditPage] onCardImageChanged url", fileUrl);
        setFormData({
            ...formData,
            cardImage: fileUrl
        })
    }

    const onDetailImagesFileSelected = async (files) => {
        // multiple
        console.log("[ClinicEditPage] onDetailImagesFileSelected", files);
        let urls = [];
        if (files.length > 0) {
            for await (const file of files) {
                const uploadUrl = await StaffClient.getS3UploadUrl(file);
                const url = await FileUtils.uploadS3File(file, uploadUrl);
                urls.push(url);
            }
        }
        console.log("[ClinicEditPage] onDetailImagesFileSelected urls", urls);
        const newImages = [];
        if(!_.isEmpty(formData.detailImages)){
            newImages.push(...formData.detailImages);
        }
        newImages.push(...urls);
        setFormData({
            ...formData,
            detailImages: newImages
        })
    }

    if (_.isNil(eventInfo) || _.isNil(formData)) {
        return <SpinnerMedium />
    }

    return (
        <Card>
            <div className="p-3 flex flex-col space-y-2">
                <PageTitle>이벤트정보 수정</PageTitle>
                <div>
                    <div className="font-semibold">
                        이벤트 ID
                    </div>
                    <Input
                        value={eventId}
                        disabled={true}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        의원
                    </div>
                    <ComboBox
                        items={infoService.getClinics()}
                        selectedItem={selectedClinicItem}
                        onChange={(item) => { setSelectedClinicItem(item); setFormData({ ...formData, clinicId: item?.id }) }}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        이벤트명
                    </div>
                    <Input
                        value={formData.title}
                        onChange={(value) => setFormData({ ...formData, title: value })}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        내용
                    </div>
                    <Textarea
                        value={formData.content}
                        onChange={(value) => setFormData({ ...formData, content: value })}
                        resizable={true}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        시술명
                    </div>
                    <EmptyForm>
                        <div className="p-2">
                            <MultiBadgeCombo
                                items={infoService.getTreatments()}
                                selectedItems={formData?.treatmentIds?.map((id) => infoService.getTreatmentById(id))}
                                onSelectedItemsChange={(items) => {
                                    console.log("[EventEditPage] onSelectedItemsChange", items);
                                    setFormData({
                                        ...formData,
                                        treatmentIds: items.map((item) => item.id)
                                    })
                                }}
                            />
                        </div>
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        대표 이미지
                    </div>
                    <EmptyForm>
                        <SingleImageUploader
                            imgUrl={formData.mainImage}
                            onFileSelected={onMainImageChanged}
                            onChange={(url) => {
                                setFormData({
                                    ...formData,
                                    mainImage: url
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        카드 이미지
                    </div>
                    <EmptyForm>
                        <SingleImageUploader
                            imgUrl={formData.cardImage}
                            onFileSelected={onCardImageChanged}
                            onChange={(url) => {
                                setFormData({
                                    ...formData,
                                    cardImage: url
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        상세 이미지
                    </div>
                    <EmptyForm>
                        <MultiImageUploader
                            imgUrls={formData.detailImages}
                            onFileSelected={onDetailImagesFileSelected}
                            onChange={(urls) => {
                                console.log("[EventEditPage] onChange urls", urls);
                                setFormData({
                                    ...formData,
                                    detailImages: _.isEmpty(urls) ? null : urls
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                {/* <div>
                    <div className="font-semibold">
                        소속 의원
                    </div>
                    <ComboBox
                        items={clinicItems}
                        selectedItem={selectedClinicItem}
                        onChange={(item) => { setSelectedClinicItem(item); setFormData({ ...formData, clinicId: item?.id }) }}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        프로필 이미지
                    </div>
                    <EmptyForm>
                        <SingleImageUploader
                            imgUrl={formData.profileImage}
                            onFileSelected={onProfileImageFileSelected}
                            onChange={(url) => {
                                setFormData({
                                    ...formData,
                                    profileImage: url
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        진료과목
                    </div>
                    <EmptyForm>
                        <div className="p-2">
                            <MultiBadgeCombo
                                items={infoService.getSpecialties()}
                                selectedItems={formData?.specialtyIds?.map((id) => infoService.getSpecialtyById(id))}
                                setSelectedItems={(items) => {
                                    console.log("[ClinicEditPage] setSelectedItems", items);
                                    setFormData({
                                        ...formData,
                                        specialtyIds: items.map((item) => item.id)
                                    })
                                }}
                            />
                        </div>
                    </EmptyForm>
                </div> */}
                <div className="border-t border-gray-300"></div>
                <div>
                    <div className="flex justify-between space-x-2">
                        <div>
                            <Button
                                onButtonClicked={() => { navigate("/main/doctor") }}
                            >
                                목록
                            </Button>
                        </div>
                        <div className="flex space-x-2">
                            <div>
                                <Button
                                    onButtonClicked={() => {
                                        const tempEventInfo = {
                                            ..._.omit(formData, ["clinicId", "treatmentIds"]),
                                            clinic: selectedClinicItem,
                                            treatments: formData.treatmentIds.map((id) => infoService.getTreatmentById(id))
                                        }
                                        window.localStorage.setItem("tempEventInfo", JSON.stringify(tempEventInfo));
                                        window.open(`/preview/event/pv`, "_blank");
                                    }}
                                >
                                    미리보기
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onButtonClicked={onSave}
                                >
                                    저장
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Card>
    )
}

export default EventEditPage;
