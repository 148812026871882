import Input from 'components/Form/Input';
import axios from 'axios';
import Card from 'components/Card';
import SpinnerMedium from 'components/Spinner/Medium';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Textarea from 'components/Form/Textarea';
import Button from 'components/Form/Button';
import PageTitle from 'components/Title/Page';
import { staffClient } from 'common/apis/staff';

const ArticleEditor = () => {
    const { articleId } = useParams();
    const [article, setArticle] = useState(null);
    const [formData, setFormData] = useState({} as any);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("[ArticleEditor] articleId", articleId);
        if (_.isNil(articleId)) {
            return;
        }
        fetchData();
    }, [articleId]);

    const fetchData = async () => {
        try {
            // const ret = await axios.get(`https://rest.miview.shop/article/${articleId}`);
            const ret = await staffClient.get(`/article/${articleId}`);
            console.log("[fetchData] ret", ret);
            setArticle(ret.data);
            const tempFormData = {
                title: ret.data.title,
                content: ret.data.content
            }
            setFormData(tempFormData);
        } catch (e) {
            console.error("[fetchData] error", e);
            return null;
        }
    }

    const onSaved = async () => {
        console.log("[ArticleEditor] onSaved", formData);
        try {
            // const ret = await axios.patch(`https://rest.miview.shop/article/${articleId}`, formData);
            const ret = await staffClient.patch(`/article/${articleId}`, formData);
            console.log("[onSaved] ret", ret);
            navigate(`/test/board/${article.board.id}`);
        } catch (e) {
            console.error("[onSaved] error", e);
        }
    }

    if (_.isNil(article)) {
        return <SpinnerMedium />;
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>
                    게시글 수정
                </PageTitle>
                <Input
                    upperLabel="제목"
                    value={formData.title}
                    onChange={(value) => {
                        setFormData((prev => {
                            return {
                                ...prev,
                                title: value
                            }
                        }));
                    }}
                />
                <Textarea
                    upperLabel="내용"
                    value={formData.content}
                    onChange={(value) => {
                        setFormData((prev => {
                            return {
                                ...prev,
                                content: value
                            }
                        }));
                    }}
                />
                <div className="flex mt-2 justify-between">
                    <div>
                        <Button
                            onButtonClicked={() => {
                                console.log("[ArticleEditor] navigate to board");
                                navigate(`/test/board/${article.board.id}`);
                            }}
                        >
                            뒤로가기
                        </Button>
                    </div>
                    <div>
                        <Button
                            onButtonClicked={onSaved}
                        >
                            저장
                        </Button>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default ArticleEditor;
