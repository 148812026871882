import Card from "components/Card";
import Button from "components/Form/Button";
import PageTitle from "components/Title/Page";
import React from "react";
import { memberClient } from "common/apis/member";

const OauthPage = () => {

    const onLineLoginButtonClicked = async () => {
        console.log("[onLineLoginButtonClicked]");
        alert("현재 작동하지 않는 테스트")
        // const url = LineService.createLineLoginUrl("mystate");
        const ret = await memberClient.get("/member/line/url");
        const url = ret.data;
        console.log("[onLineLoginButtonClicked] ret", url);
        const popupWindow = window.open(url, 'newWindow', 'width=600, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>소셜로그인 테스트</PageTitle>
                <div className="flex space-x-2">
                    <div>
                        <Button onButtonClicked={onLineLoginButtonClicked}>라인 로그인</Button>
                    </div>
                    <div>
                        <Button>네이버 로그인</Button>
                    </div>
                    <div>
                        <Button>카카오 로그인</Button>
                    </div>
                    <div>
                        <Button>구글 로그인</Button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default OauthPage;