import React, { useState, useEffect, useMemo } from "react";
import SimpleTable, { SimpleTableProps } from "../Simple";
import SpinnerMedium from "components/Spinner/Medium";
import IconButton from "components/Form/Button/Icon";
import { PlusIcon, MinusIcon, ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useModal } from "components/Modal/Simple";
import _ from 'lodash';
import EditForm from "./EditForm";
import { findColumnInfo } from "../Simple";

const EMPTY_DATA = [];

export interface CRUDTableProps extends SimpleTableProps {
    onEdit?: (row: any) => Promise<boolean>;
    onCreate?: (row: any) => Promise<boolean>;
    onDelete?: (row: any) => Promise<boolean>;
    isReordable?: boolean;
    onReorder?: (tableData: any, selectedData: any, isUp?: boolean) => Promise<boolean>;
    isCreatable?: boolean;
    isEditable?: boolean;
    isDeletable?: boolean;
    filtersComponent?: React.ReactNode;
}

const CRUDTable = ({
    data = EMPTY_DATA,
    columns = EMPTY_DATA,
    filtersComponent = null,
    onEdit = async (row) => { return true },
    onCreate = async (row) => { return true },
    onDelete = async (row) => { return false },
    onReorder = async (allRows) => { return false },
    isReordable = false,
    isCreatable = true,
    isEditable = true,
    isDeletable = true,
    ...rest
}: CRUDTableProps) => {
    const { Modal: EditModal, openModal: openEditModal, closeModal: closeEditModal } = useModal({});
    const { Modal: CreateModal, openModal: openCreateModal, closeModal: closeCreateModal } = useModal({});
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [columnNames, setColumnNames] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState({});

    const onRowClicked = (rowIdx) => {
        // console.log("[CRUDTable] onRowClicked", rowIdx);
        // toggle
        if (selectedRowIndex === rowIdx) {
            setSelectedRowIndex(null);
        } else {
            setSelectedRowIndex(rowIdx);
        }
    }

    useEffect(() => {
        setSelectedRowIndex(null);
    }, [data])

    useEffect(() => {
        if (_.isNil(selectedRowIndex)) {
            setSelectedRowData({});
        } else {
            setSelectedRowData(data[selectedRowIndex]);
        }
    }, [selectedRowIndex])

    useEffect(() => {
        if (_.isNil(data)) {
            return;
        }
        const cnsOfData = _.uniq(data.map((row) => _.omitBy(row, _.isObject)).map((row) => Object.keys(row)).flat());
        const cnsWithColumnInfos = _.uniq([...columns.map((column) => column.field), ...cnsOfData]);
        setColumnNames(cnsWithColumnInfos);
    }, [data])

    const rowClassNames = {
        // ...(!_.isNil(selectedRow) && { [selectedRow]: "shadow-inner ring ring-indigo-500 ring-[2px]" })
        ...(!_.isNil(selectedRowIndex) && { [selectedRowIndex]: "selectedRow" })

    }

    // all row's data keys
    const onCreateModalSaved = async (data) => {
        console.log("[CRUDTable] onCreateModalSaved", data);
        const success = await onCreate(data);
        if (success) {
            closeCreateModal();
        }
    }

    const onEditModalSaved = async (data) => {
        console.log("[CRUDTable] onEditModalSaved", data);
        const success = await onEdit(data);
        if (success) {
            closeEditModal();
        }
    }

    const onDeleteButtonClicked = () => {
        console.log("[CRUDTable] onDeleteButtonClicked");
        onDelete(selectedRowData);
    }

    // reordable features

    const onUpButtonClicked = () => {
        console.log("[CRUDTable] onUpButtonClicked");
        const newTableData = [
            ...data.slice(0, selectedRowIndex - 1),
            data[selectedRowIndex],
            data[selectedRowIndex - 1],
            ...data.slice(selectedRowIndex + 1)
        ];
        console.log("[CRUDTable] onUpButtonClicked newTableData", newTableData)
        onReorder(newTableData, selectedRowData, true);
    }

    const onDownButtonClicked = () => {
        console.log("[CRUDTable] onDownButtonClicked");
        const newTableData = [
            ...data.slice(0, selectedRowIndex),
            data[selectedRowIndex + 1],
            data[selectedRowIndex],
            ...data.slice(selectedRowIndex + 2)
        ];
        console.log("[CRUDTable] onDownButtonClicked newTableData", newTableData)
        onReorder(newTableData, selectedRowData, false);
    }

    return (
        <div>
            <div className="flex justify-between space-x-2 pb-2 items-end overflow-auto">
                <div>
                    {filtersComponent}
                </div>
                <div className="flex space-x-2">
                    {isCreatable &&
                        <div>
                            <IconButton className="bg-green-500 text-white" onButtonClicked={() => { setSelectedRowIndex(null); openCreateModal() }}>
                                <PlusIcon className="h-5 w-5" />
                            </IconButton>
                        </div>
                    }
                    {isReordable &&
                        <div>
                            <IconButton className="bg-blue-500 text-white" onButtonClicked={onUpButtonClicked} disabled={_.isNil(selectedRowIndex) || selectedRowIndex === 0}>
                                <ArrowUpIcon className="h-5 w-5" />
                            </IconButton>
                        </div>
                    }{isEditable &&
                        <div>
                            <IconButton className="bg-blue-500 text-white" onButtonClicked={() => { openEditModal() }} disabled={_.isNil(selectedRowIndex)}>
                                <PencilSquareIcon className="h-5 w-5" />
                            </IconButton>
                        </div>
                    }
                    {isReordable &&
                        <div>
                            <IconButton className="bg-blue-500 text-white" onButtonClicked={onDownButtonClicked} disabled={_.isNil(selectedRowIndex) || selectedRowIndex === data.length - 1}>
                                <ArrowDownIcon className="h-5 w-5" />
                            </IconButton>
                        </div>
                    }
                    {isDeletable &&
                        <div>
                            <IconButton className="bg-red-500 text-white" onButtonClicked={onDeleteButtonClicked} disabled={_.isNil(selectedRowIndex)}>
                                <MinusIcon className="h-5 w-5" />
                            </IconButton>
                        </div>
                    }
                </div>

            </div>
            <SimpleTable data={data} columns={columns} onRowClicked={onRowClicked} rowClassNames={rowClassNames} onSorted={() => setSelectedRowIndex(null)} {...rest}></SimpleTable>
            <CreateModal>
                <EditForm
                    title={"데이터 생성"}
                    columns={columns}
                    columnNames={columnNames.filter((fieldName) => findColumnInfo(columns, fieldName)?.creatable === true)}
                    selectableColumnNames={columnNames.filter((fieldName) => !_.isNil(findColumnInfo(columns, fieldName)?.selectable))}
                    data={selectedRowData}
                    onCancel={closeCreateModal}
                    onSave={onCreateModalSaved}
                />
            </CreateModal>
            <EditModal>
                <EditForm
                    title={"데이터 수정"}
                    columns={columns}
                    columnNames={columnNames.filter((fieldName) => findColumnInfo(columns, fieldName)?.editable === true)}
                    selectableColumnNames={columnNames.filter((fieldName) => !_.isNil(findColumnInfo(columns, fieldName)?.selectable))}
                    data={selectedRowData}
                    onCancel={closeEditModal}
                    onSave={onEditModalSaved}
                />
            </EditModal>
        </div>
    )
}

export default CRUDTable;
