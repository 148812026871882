
import React from "react";
import { Routes, Route } from "react-router-dom";
import MyPage from "pages/Account/MyPage";

const AccountRoute = () => (
    <Routes>
        <Route path="/my-page" element={<MyPage />}></Route>
    </Routes>
)

export default AccountRoute;