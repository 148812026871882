import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import Input from "components/Form/Input";
import PageTitle from "components/Title/Page";
import axios from "axios";
import _ from "lodash";
import SpinnerMedium from "components/Spinner/Medium";
import Button from "components/Form/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Textarea from "components/Form/Textarea";
import { infoService } from "common/services/info";
import KoreanAddressInput, { KoreanAddressType } from "common/forms/KoreanAddressInput";
import SingleImageUploader from "components/Form/Input/Image/SingleUploader";
import MultiImageUploader from "components/Form/Input/Image/MultiUploader";
import { FileUtils } from "components/Utils/file";
import EmptyForm from "components/Form/Empty";
import MultiBadgeCombo from "components/Form/MultiBadgeCombo";
import { StaffClient, staffClient } from "common/apis/staff";

const ClinicEditPage = () => {
    const { clinicId } = useParams();
    const [clinicInfo, setClinicInfo] = useState(null);
    const [formData, setFormData] = useState(null);
    const [initialAddress, setInitialAddress] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchClinicInfo();
    }, [])

    const fetchClinicInfo = async () => {
        try {
            // const ret = await axios.get(`https://rest.miview.shop/clinic/${clinicId}`);
            const ret = await staffClient.get(`/clinic/${clinicId}`);
            console.log("[ClinicEditPage] fetchClinicInfo ret", ret);
            const clinicData = ret.data;
            setClinicInfo(clinicData);
            setFormData({
                clinicId: clinicData.id,
                name: clinicData.name,
                description: clinicData.description,
                address: clinicData.address,
                profileImage: clinicData.profileImage,
                bannerImage: clinicData.bannerImage,
                introductionImages: clinicData.introductionImages,
                specialtyIds: clinicData.specialties?.map((sp) => sp.id)
            })
            setInitialAddress(clinicData.address);
        } catch (e) {
            console.log("[ClinicEditPage] fetchClinicInfo error", e);
        }
    }

    const onAddressChanged = (addressObj: KoreanAddressType) => {
        console.log("[ClinicEditPage] onAddressChanged", addressObj);
        setFormData({
            ...formData,
            address: addressObj
        })
    }

    const onSave = async () => {
        console.log("[ClinicEditPage] onSave", formData);
        try {
            // const ret = await axios.patch(`https://rest.miview.shop/clinic/${clinicId}`, formData);
            const ret = await staffClient.patch(`/clinic/${clinicId}`, formData);
            console.log("[ClinicEditPage] onSave ret", ret);
            infoService.fetchClinics();
            toast.success("의원 정보가 수정되었습니다.");
            navigate("/main/clinic");
        } catch (e) {
            console.log("[ClinicEditPage] onSave error", e);
            toast.error("의원 정보 수정 중 오류가 발생했습니다.");
        }
    }

    const onProfileImageFileSelected = async (files) => {
        console.log("[ClinicEditPage] onProfileImageFileSelected", files);
        const uploadUrl = await StaffClient.getS3UploadUrl(files[0].name);
        const url = await FileUtils.uploadS3File(files[0], uploadUrl);
        console.log("[ClinicEditPage] onProfileImageFileSelected url", url);
        setFormData({
            ...formData,
            profileImage: url
        })
    }

    const onBannerImageFileSelected = async (files) => {
        console.log("[ClinicEditPage] onBannerImageFileSelected", files);
        const uploadUrl = await StaffClient.getS3UploadUrl(files[0].name);
        const url = await FileUtils.uploadS3File(files[0], uploadUrl);
        console.log("[ClinicEditPage] onBannerImageFileSelected url", url);
        setFormData({
            ...formData,
            bannerImage: url
        })
    }

    const onIntroductionImagesFileSelected = async (files) => {
        // multiple
        console.log("[ClinicEditPage] onIntroductionImagesFileSelected", files);
        let urls = [];
        if (files.length > 0) {
            for await (const file of files) {
                const uploadUrl = await StaffClient.getS3UploadUrl(file.name);
                const url = await FileUtils.uploadS3File(file, uploadUrl);
                urls.push(url);
            }
        }
        console.log("[ClinicEditPage] onIntroductionImagesFileSelected urls", urls);
        setFormData({
            ...formData,
            introductionImages: urls
        })
    }

    if (_.isNil(clinicInfo) || _.isNil(formData)) {
        return <SpinnerMedium />
    }

    return (
        <Card>
            <div className="p-3 flex flex-col space-y-2">
                <PageTitle>의원 정보 수정</PageTitle>
                <div>
                    <div className="font-semibold">
                        의원 ID
                    </div>
                    <Input
                        value={clinicId}
                        disabled={true}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        의원명
                    </div>
                    <Input
                        value={formData.name}
                        onChange={(value) => setFormData({ ...formData, name: value })}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        설명
                    </div>
                    <Textarea
                        value={formData.description}
                        onChange={(value) => setFormData({ ...formData, description: value })}
                        resizable={true}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        주소
                    </div>
                    <EmptyForm>
                        <div className="p-2">
                            <KoreanAddressInput initialAddress={initialAddress} onChange={onAddressChanged} />
                        </div>
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        진료과목
                    </div>
                    <EmptyForm>
                        <div className="p-2">
                            <MultiBadgeCombo
                                items={infoService.getSpecialties()}
                                selectedItems={formData?.specialtyIds?.map((id) => infoService.getSpecialtyById(id))}
                                onSelectedItemsChange={(items) => {
                                    console.log("[ClinicEditPage] onSelectedItemsChange", items);
                                    setFormData({
                                        ...formData,
                                        specialtyIds: items.map((item) => item.id),
                                    })
                                }}
                            />
                        </div>
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        프로필 이미지
                    </div>
                    <EmptyForm>
                        <SingleImageUploader
                            imgUrl={formData.profileImage}
                            onFileSelected={onProfileImageFileSelected}
                            onChange={(url) => {
                                setFormData({
                                    ...formData,
                                    profileImage: url
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        배너 이미지
                    </div>
                    <EmptyForm>
                        <SingleImageUploader
                            imgUrl={formData.bannerImage}
                            onFileSelected={onBannerImageFileSelected}
                            onChange={(url) => {
                                setFormData({
                                    ...formData,
                                    bannerImage: url
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        병원 소개 이미지
                    </div>
                    <EmptyForm>
                        <MultiImageUploader
                            imgUrls={formData.introductionImages}
                            onFileSelected={onIntroductionImagesFileSelected}
                            onChange={(urls) => {
                                console.log("[ClinicEditPage] onChange urls", urls);
                                setFormData({
                                    ...formData,
                                    introductionImages: _.isEmpty(urls) ? null : urls
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div className="border-t border-gray-300"></div>
                <div>
                    <div className="flex justify-between space-x-2">
                        <div>
                            <Button
                                onButtonClicked={() => { navigate("/main/Clinic") }}
                            >
                                목록
                            </Button>
                        </div>
                        <div className="flex space-x-2">
                            <div>
                                <Button
                                    onButtonClicked={() => {
                                        const tempClinicInfo = {
                                            ..._.omit(formData, ["specialtyIds"]),
                                            specialties: formData.specialtyIds.map((id) => infoService.getSpecialtyById(id)),
                                            doctors: clinicInfo.doctors,
                                            events: clinicInfo.events,
                                            reviews: clinicInfo.reviews
                                        }
                                        window.localStorage.setItem("tempClinicInfo", JSON.stringify(tempClinicInfo));
                                        window.open(`/preview/clinic/pv`, "_blank")
                                    }}
                                >
                                    미리보기
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onButtonClicked={onSave}
                                >
                                    저장
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ClinicEditPage;
