
import React from "react";
import { Routes, Route } from "react-router-dom";
import StaffList from "pages/User/Staff/List";

const UserRoute = () => (
    <Routes>
        <Route path="/staff" element={<StaffList />}></Route>
    </Routes>
)

export default UserRoute;