import { Column } from "components/Table/Simple";
import prettyBytes from 'pretty-bytes';

export const columns: Column[] = [
    {
        field: "name",
        label: "파일명"
    },
    // {
    //     field: "type",
    //     label: "파일 타입"
    // },
    {
        field: "size",
        label: "파일 크기",
        render: (value) => {
            return prettyBytes(value);
        },
        align: "right"
    }
]