import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import Input from "components/Form/Input";
import PageTitle from "components/Title/Page";
import axios from "axios";
import _ from "lodash";
import SpinnerMedium from "components/Spinner/Medium";
import { infoService } from "common/services/info";
import ComboBox from "components/Form/Combobox";
import Button from "components/Form/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Textarea from "components/Form/Textarea";
import EmptyForm from "components/Form/Empty";
import SingleImageUploader from "components/Form/Input/Image/SingleUploader";
import { FileUtils } from "components/Utils/file";
import MultiBadgeCombo from "components/Form/MultiBadgeCombo";
import { StaffClient, staffClient } from "common/apis/staff";

const DoctorEditPage = () => {
    const { doctorId } = useParams();
    const [doctorInfo, setDoctorInfo] = useState(null);
    const [clinicItems, setClinicItems] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [formData, setFormData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const clinics = infoService.getClinics();
        console.log("[DoctorEditPage] useEffect clinics", clinics);
        setClinicItems(clinics);
        fetchDoctorInfo();
    }, [])

    const fetchDoctorInfo = async () => {
        try {
            // const ret = await axios.get(`https://rest.miview.shop/doctor/${doctorId}`);
            const ret = await staffClient.get(`/doctor/${doctorId}`);
            console.log("[DoctorEditPage] fetchDoctorInfo ret", ret);
            const doctorData = ret.data;
            setDoctorInfo(doctorData);
            setFormData({
                doctorId: doctorData.id,
                name: doctorData.name,
                description: doctorData.description,
                clinicId: doctorData.clinic?.id,
                profileImage: doctorData.profileImage,
                specialtyIds: doctorData.specialties?.map((sp) => sp.id)
            })
            const clinics = infoService.getClinics();
            const clinicItem = clinics.find((item) => item.id === doctorData.clinic?.id);
            console.log("[DoctorEditPage] fetchDoctorInfo clinicItem", clinicItem);
            setSelectedClinicItem(clinicItem);
        } catch (e) {
            console.log("[DoctorEditPage] fetchDoctorInfo error", e);
        }
    }

    const onSave = async () => {
        console.log("[DoctorEditPage] onSave", formData);
        try {
            // const ret = await axios.patch(`https://rest.miview.shop/doctor/${doctorId}`, formData);
            const ret = await staffClient.patch(`/doctor/${doctorId}`, formData);
            console.log("[DoctorEditPage] onSave ret", ret);
            toast.success("의사 정보가 수정되었습니다.");
            navigate("/main/doctor");
        } catch (e) {
            console.log("[DoctorEditPage] onSave error", e);
            toast.error("의사 정보 수정 중 오류가 발생했습니다.");
        }
    }

    const onProfileImageFileSelected = async (files) => {
        console.log("[ClinicEditPage] onProfileImageFileSelected", files);
        // const url = await FileUtils.uploadS3File(files[0]);
        const uploadUrl = await StaffClient.getS3UploadUrl(files[0].name);
        console.log("[ClinicEditPage] onProfileImageFileSelected uploadUrl", uploadUrl);
        const url = await FileUtils.uploadS3File(files[0], uploadUrl);
        console.log("[ClinicEditPage] onProfileImageFileSelected url", url);
        setFormData({
            ...formData,
            profileImage: url
        })
    }

    if (_.isNil(doctorInfo) || _.isNil(formData)) {
        return <SpinnerMedium />
    }

    return (
        <Card>
            <div className="p-3 flex flex-col space-y-2">
                <PageTitle>의사 정보 수정</PageTitle>
                <div>
                    <div className="font-semibold">
                        의사 ID
                    </div>
                    <Input
                        value={doctorId}
                        disabled={true}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        의사명
                    </div>
                    <Input
                        value={formData.name}
                        onChange={(value) => setFormData({ ...formData, name: value })}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        연혁
                    </div>
                    <Textarea
                        value={formData.description}
                        onChange={(value) => setFormData({ ...formData, description: value })}
                        resizable={true}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        소속 의원
                    </div>
                    <ComboBox
                        items={clinicItems}
                        selectedItem={selectedClinicItem}
                        onChange={(item) => { setSelectedClinicItem(item); setFormData({ ...formData, clinicId: item?.id }) }}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        프로필 이미지
                    </div>
                    <EmptyForm>
                        <SingleImageUploader
                            imgUrl={formData.profileImage}
                            onFileSelected={onProfileImageFileSelected}
                            onChange={(url) => {
                                setFormData({
                                    ...formData,
                                    profileImage: url
                                })
                            }}
                        />
                    </EmptyForm>
                </div>
                <div>
                    <div className="font-semibold">
                        진료과목
                    </div>
                    <EmptyForm>
                        <div className="p-2">
                            <MultiBadgeCombo
                                items={infoService.getSpecialties()}
                                selectedItems={formData?.specialtyIds?.map((id) => infoService.getSpecialtyById(id))}
                                onSelectedItemsChange={(items) => {
                                    console.log("[ClinicEditPage] onSelectedItemsChange", items);
                                    setFormData({
                                        ...formData,
                                        specialtyIds: items.map((item) => item.id)
                                    })
                                }}
                            />
                        </div>
                    </EmptyForm>
                </div>
                <div className="border-t border-gray-300"></div>
                <div>
                    <div className="flex justify-between space-x-2">
                        <div>
                            <Button
                                onButtonClicked={() => { navigate("/main/doctor") }}
                            >
                                목록
                            </Button>
                        </div>
                        <div className="flex space-x-2">
                            <div>
                                <Button
                                    onButtonClicked={() => {
                                        const tempDoctorInfo = {
                                            ..._.omit(formData, ["specialtyIds", "clinicId"]),
                                            clinic: selectedClinicItem,
                                            specialties: formData.specialtyIds.map((id) => infoService.getSpecialtyById(id)),
                                            reviews: doctorInfo.reviews
                                        }
                                        window.localStorage.setItem("tempDoctorInfo", JSON.stringify(tempDoctorInfo));
                                        window.open(`/preview/doctor/pv`, "_blank");
                                    }}
                                >
                                    미리보기
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onButtonClicked={onSave}
                                >
                                    저장
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Card>
    )
}

export default DoctorEditPage;
