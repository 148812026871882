import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import Home from "pages/Home";
import MainRoute from "containers/SiteRoute/Main";
import CategoryRoute from "containers/SiteRoute/Category";
import UserRoute from "containers/SiteRoute/User";
import AccountRoute from "containers/SiteRoute/Account";
import TestRoute from "containers/SiteRoute/Test";

export default () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={'/home/'} />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/main/*" element={<MainRoute />}></Route>
            <Route path="/category/*" element={<CategoryRoute />}></Route>
            <Route path="/user/*" element={<UserRoute />}></Route>
            <Route path="/account/*" element={<AccountRoute />}></Route>
            <Route path="/test/*" element={<TestRoute />}></Route>
        </Routes>
    )
}