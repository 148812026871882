import Card from "components/Card";
import Button from "components/Form/Button";
import React, { useEffect, useState } from "react"
import { FileUtils } from "components/Utils/file";
import MultiImageUploader from "components/Form/Input/Image/MultiUploader";
import SingleImageUploader from "components/Form/Input/Image/SingleUploader";
import { UrlUtils } from "components/Utils/url";
import { StaffClient } from "common/apis/staff";

const ImageUploadTest = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [imgUrls, setImgUrls] = useState([]);

    const onSingleFileSelected = async (files) => {
        console.log("onSingleFileSelected", files);
        const uploadUrl = await StaffClient.getS3UploadUrl(files[0]);
        const url = await FileUtils.uploadS3File(files[0], uploadUrl);
        console.log("[onSingleFileSelected] url", url);
        setImgUrl(UrlUtils.convertCdnUrl(url));
    }
    const onMultipleFileSelected = async (files) => {
        console.log("onMultipleFileSelected", files);
        let urls = [];
        if (files.length > 0) {
            for await (const file of files) {
                const uploadUrl = await StaffClient.getS3UploadUrl(file);
                const url = await FileUtils.uploadS3File(file, uploadUrl);
                urls.push(UrlUtils.convertCdnUrl(url));
            }
        }
        console.log("!!!!! URLS", urls)
        setImgUrls([...imgUrls, ...urls]);
    }

    return (
        <Card>
            <div className="p-3 flex flex-col space-y-2">
                <div>
                    <div className="font-semibold">
                        이미지 업로드 기본
                    </div>
                    <Button
                        onButtonClicked={async () => {
                            // select image file
                            // create file picker
                            const filePicker = document.createElement('input');
                            filePicker.type = 'file';
                            filePicker.accept = 'image/*';
                            filePicker.click();
                            filePicker.onchange = async (e) => {
                                const files = (e.target as HTMLInputElement).files;
                                if (files && files.length > 0) {
                                    const file = files[0];
                                    const uploadUrl = await StaffClient.getS3UploadUrl(file);
                                    const url = await FileUtils.uploadS3File(file, uploadUrl);
                                    console.log("!!!!! URL", url);
                                }
                            }

                        }}
                    >
                        이미지 업로드
                    </Button>
                </div>
                <div>
                    <div className="font-semibold">
                        단일 이미지 업로더
                    </div>
                    <div>
                        <Card paddingClassNames='p-0'>
                            <SingleImageUploader
                                imgUrl={imgUrl}
                                onFileSelected={onSingleFileSelected}
                                onChange={(imgUrl) => {
                                    setImgUrl(imgUrl);
                                }}
                            />
                        </Card>
                    </div>
                </div>
                <div>
                    <div className="font-semibold">
                        다중 이미지 업로더
                    </div>
                    <div>
                        <Card paddingClassNames='p-0'>
                            <MultiImageUploader
                                imgUrls={imgUrls}
                                onFileSelected={onMultipleFileSelected}
                                onChange={(imgUrls) => {
                                    setImgUrls(imgUrls);
                                }}
                            />
                        </Card>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ImageUploadTest;