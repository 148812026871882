// import { staffClient } from 'common/apis/staff';
import axios from 'axios';
import { CDN_URL } from './url';

export namespace FileUtils {
    export const uploadS3File = async (file: File, url: string) => {
        try {
            const urlObj = new URL(url);
            const cdnUrl = `${CDN_URL}${urlObj.pathname}?${urlObj.searchParams.toString()}`;
            // Don't Use Staff Client For This
            const uploadResponse = await axios.put(cdnUrl, file, {
                headers: {
                    'Content-Type': file.type
                }
            });

            if (uploadResponse.status === 200) {
                // insertToEditor(url.split('?')[0]);
                return url.split('?')[0];
            } else {
                console.error('Failed to upload image.');
            }
        } catch (error) {
            console.error('An error occurred during the image upload process:', error);
        }
    }

    export const getFileHash = async (file) => {
        const fileContent = await file.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-256', fileContent);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }

}
