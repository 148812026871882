import axios from "axios";
import Card from "components/Card";
import PagingTable, { EMPTY_PAGING_TABLE_RET } from "components/Table/Paging";
import PageTitle from "components/Title/Page";
import React, { useEffect, useState } from "react";
import { columns } from "./config";
import _ from "lodash";
import { staffClient } from "common/apis/staff";

const StaffList = () => {
    const [staffs, setStaffs] = useState(null);
    const [refreshKey, setRefreshKey] = useState(null);

    useEffect(() => {

    }, [])

    const fetchStaffs = async (page, limit) => {
        try {
            const params = {
                offset: (page - 1) * limit,
                limit,
            }
            const ret = await staffClient.get(`/staff`, { params });
            console.log("[fetchStaffs] ret", ret);
            return {
                data: ret.data?.data,
                total: ret.data?.meta?.total,
                page: page,
            }
        } catch (e) {
            return EMPTY_PAGING_TABLE_RET;
        }
    }

    const onCreated = async (row) => {
        try {
            const ret = await staffClient.post(`/staff`, row);
            console.log("[onCreated] ret", ret);
            setRefreshKey(_.uniqueId());
            return ret.data;
        } catch (e) {
            console.error("[onCreated] error", e);
            return null;
        }
    }

    const onEdited = async (row) => {
        try {
            if (row.password_reset_required === true) {
                const body = {
                    user_id: row.user_id,
                }
                const resetRet = await staffClient.post(`/auth/reset-password`, body);
                console.log("[onEdited] resetRet", resetRet);
            }
            const ret = await staffClient.patch(`/staff/${row.id}`, row);
            console.log("[onEdited] ret", ret);
            setRefreshKey(_.uniqueId());
            return ret.data;
        } catch (e) {
            console.error("[onEdited] error", e);
            return null;
        }
    }

    const onDeleted = async (row) => {
        try {
            const ret = await staffClient.delete(`/staff/${row.id}`);
            console.log("[onDeleted] ret", ret);
            setRefreshKey(_.uniqueId());
            return ret.data;
        } catch (e) {
            console.error("[onDeleted] error", e);
            return null;
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>직원 관리</PageTitle>
                <PagingTable
                    refreshKey={refreshKey}
                    fetchData={fetchStaffs}
                    columns={columns}
                    isCrudable={true}
                    onCreate={onCreated}
                    onEdit={onEdited}
                    onDelete={onDeleted}
                />
            </div>
        </Card>
    )
}

export default StaffList;
