import React, { useState } from "react";
import Card from "components/Card";
import PagingTable from "components/Table/Paging";
import axios from "axios";
import { columns } from "./config";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Column } from "components/Table/Simple";
import { staffClient } from "common/apis/staff";

const ClinicPage = () => {
    const [refreshKey, setRefreshKey] = useState(_.uniqueId());
    const navigate = useNavigate();
    
    const fetchData = async (page, limit) => {
        try {
            const params = {
                offset: (page - 1) * limit,
                limit: limit
            }
            // const ret = await axios.get(`https://rest.miview.shop/clinic`, { params });
            const ret = await staffClient.get("/clinic", { params });
            const { data, total } = ret.data;
            return {
                data,
                page,
                total
            }
        } catch (e) {
            console.log("[ClinicPage] fetchData error", e);
            return {
                data: null,
                page,
                total: 0
            }
        }
    }

    const postData = async (row) => {
        try {
            // const ret = await axios.post(`https://rest.miview.shop/clinic`, row);
            const ret = await staffClient.post("/clinic", row);
            return ret.data;
        } catch (e) {
            console.log("[ClinicPage] postData error", e);
            return null;
        }
    }

    const onCreate = async (row) => {
        console.log("[ClinicPage] onCreate", row);
        const ret = await postData(row);
        if (ret) {
            setRefreshKey(_.uniqueId());
            return true;
        }
    }

    //depricated
    const onEdit = async (row) => {
        console.log("[onEdit] row", row);
        try {
            // const ret = await axios.patch(`https://rest.miview.shop/clinic/${row.id}`, row);
            const ret = await staffClient.patch(`/clinic/${row.id}`, row);
            console.log("[onEdit] ret", ret);
            setRefreshKey(_.uniqueId());
            return true;
        } catch (e) {
            console.log("[ClinicPage] onEdit error", e);
            return null;
        }
    }

    const onDetailEditClicked = (row) => {
        console.log("[onDetailEditClicked] row", row);
        navigate(`/main/clinic/editor/${row.id}`);
    }

    const columnsWithActions: Column[] = [
        {
            field: "actions",
            label: "상세수정",
            align: "center",
            render: (value, field, row) => (
                <div className="flex items-center cursor-pointer justify-center" onClick={() => { onDetailEditClicked(row) }}>
                    <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                </div>
            )
        },
        ...columns
    ]

    return (
        <Card>
            <div className="p-3">
                <PagingTable
                    fetchData={fetchData}
                    columns={columnsWithActions}
                    refreshKey={refreshKey}
                    windowSize={300}
                    isCrudable={true}
                    onCreate={onCreate}
                    isEditable={false}
                    onEdit={onEdit}
                />
            </div>
        </Card>
    )
};

export default ClinicPage;

