import Input from "components/Form/Input";
import CenterLayout from "common/layouts/Center";
import PageTitle from "components/Title/Page";
import React, { useState } from "react";
import KoreanAddressInput from "common/forms/KoreanAddressInput";
import SelectableList from "components/Form/Radio/StackedCard";
import Button from "components/Form/Button";
import { serviceItems, sourceItems } from "./config";
import { set } from "lodash";
import axios from "axios";
import { StaffClient, staffClient } from "common/apis/staff";
import { memberClient } from "common/apis/member";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

/*
export class CreateApplyDto {
    clinicName: string;
    address: any;
    submitter: string;
    email: string;
    phone: string;
    affiliation: string;
    position: string;
    otherInfos: any;
}
*/
const ApplyPage = () => {
    const navigate = useNavigate();

    const [clinicName, setClinicName] = useState("");
    const [address, setAddress] = useState({});
    const [submitter, setSubmitter] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [affiliation, setAffiliation] = useState("");
    const [position, setPosition] = useState("");
    const [otherInfos, setOtherInfos] = useState({});

    const handleSubmit = async () => {
        const body = {
            clinicName,
            address,
            submitter,
            email,
            phone,
            affiliation,
            position,
            otherInfos
        }
        console.log("[handleSubmit] body", body);
        if(!clinicName || !address || !submitter || !email || !phone || !affiliation || !position){
            toast.error("필수항목을 모두 입력해주세요.");
            return;
        }
        try{
            const ret = await memberClient.post("/apply", body);
            console.log("[handleSubmit] ret", ret);
            toast.success("신청이 완료되었습니다.");
            navigate("/form/completed");
        }catch(e){

        }
    }

    return (
        <div className="flex justify-center min-h-screen h-fit w-full bg-gradient-to-bl from-indigo-300 to-transparent bg-indigo-100">
            <div className="w-full max-w-screen-lg px-3  mt-[2vh] mb-[2vh]">
                <div className="mx-auto max-w-fit mt-3 mb-3 bg-white p-4 rounded-md shadow-xl">
                    <div className="">
                        <PageTitle>
                            미뷰 신규 입점 신청
                        </PageTitle>
                        <div className="flex flex-col space-y-2 md:w-[500px]">
                            <div>
                                <div>
                                    병원*
                                </div>
                                <Input
                                    value={clinicName}
                                    onChange={setClinicName}
                                />
                            </div>
                            <div>
                                <div>
                                    주소*
                                </div>
                                <div className="p-2 ring-1 ring-inset ring-gray-300 rounded-md">
                                    <KoreanAddressInput
                                        onChange={setAddress}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    신청자*
                                </div>
                                <Input
                                    value={submitter}
                                    onChange={setSubmitter}
                                />
                            </div>
                            <div>
                                <div>
                                    이메일*
                                </div>
                                <Input
                                    value={email}
                                    onChange={setEmail}
                                />
                            </div>
                            <div>
                                <div>
                                    휴대전화번호*
                                </div>
                                <Input
                                    value={phone}
                                    onChange={setPhone}
                                />
                            </div>
                            <div>
                                <div>
                                    소속*
                                </div>
                                <Input
                                    value={affiliation}
                                    onChange={setAffiliation}
                                />
                            </div>
                            <div>
                                <div>
                                    직책*
                                </div>
                                <Input
                                    value={position}
                                    onChange={setPosition}
                                />
                            </div>
                            <div>
                                <div>
                                    입점 신청 경로
                                </div>
                                <div className="p-2 ring-1 ring-inset ring-gray-300 rounded-md">
                                    <SelectableList
                                        multiSelect={true}
                                        items={sourceItems}
                                        onItemChanged={(items) => {
                                            console.log("[sourceItems] items", items);
                                            setOtherInfos((prev) => {
                                                return ({
                                                    ...prev,
                                                    source: items
                                                })
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    사용중인 서비스
                                </div>
                                <div className="p-2 ring-1 ring-inset ring-gray-300 rounded-md">
                                    <SelectableList
                                        multiSelect={true}
                                        items={serviceItems}
                                        onItemChanged={(items) => {
                                            console.log("[serviceItems] items", items);
                                            setOtherInfos((prev) => {
                                                return ({
                                                    ...prev,
                                                    service: items
                                                })
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <div>
                                    <Button onButtonClicked={handleSubmit}>
                                        제출
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplyPage;