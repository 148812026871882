import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PagingTable, { EMPTY_PAGING_TABLE_RET } from "components/Table/Paging";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import axios from "axios";
import { columns } from "./config";
import _ from "lodash";
import { Column } from "components/Table/Simple";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Input from "components/Form/Input";
import EmptyForm from "components/Form/Empty";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Button from "components/Form/Button";
import { toast } from "react-toastify";
import { staffClient } from "common/apis/staff";

const BoardViewer = () => {
    const { boardId } = useParams();
    const [refreshKey, setRefreshKey] = useState(_.uniqueId());
    const [board, setBoard] = useState(null);
    const navigate = useNavigate();

    const fetchData = async (page, limit) => {
        if (_.isNil(boardId)) {
            return EMPTY_PAGING_TABLE_RET;
        }
        try {
            // const boardRet = await axios.get(`https://rest.miview.shop/board/${boardId}`);
            const boardRet = await staffClient.get(`/board/${boardId}`);
            const tempBoard = boardRet.data;
            console.log("[fetchData] tempBoard", tempBoard);
            setBoard(tempBoard);
            const params = {
                offset: (page - 1) * limit,
                limit,
                boardId
            }
            // const ret = await axios.get(`https://rest.miview.shop/article`, { params });
            const ret = await staffClient.get("/article", { params });
            console.log("[fetchData] ret", ret);
            return {
                data: ret.data.data,
                page,
                total: ret.data?.meta?.total,
            }
        } catch (e) {
            return EMPTY_PAGING_TABLE_RET;
        }
    }

    const onCreated = async (row) => {
        try {
            const body = {
                ...row,
                boardId
            }
            // const ret = await axios.post(`https://rest.miview.shop/article`, body);
            const ret = await staffClient.post("/article", body);
            console.log("[onCreated] ret", ret);
            toast.success("게시글이 생성되었습니다.");
            setRefreshKey(_.uniqueId());
            return ret.data;
        } catch (e) {
            console.error("[onCreated] error", e);
            return null;
        }
    }

    const onDeleted = async (row) => {
        try {
            // const ret = await axios.delete(`https://rest.miview.shop/article/${row.id}`);
            const ret = await staffClient.delete(`/article/${row.id}`);
            console.log("[onDeleted] ret", ret);
            toast.success("게시글이 삭제되었습니다.");
            setRefreshKey(_.uniqueId());
            return ret.data;
        } catch (e) {
            console.error("[onDeleted] error", e);
            return null;
        }
    }

    const columnsWithActions: Column[] = [
        {
            field: "view",
            label: "보기",
            render: (value, field, row) => {
                return (
                    <div className="flex justify-center cursor-pointer" onClick={() => {
                        navigate(`/test/article/${row.id}`);
                    }}>
                        <MagnifyingGlassIcon className="size-4" />
                    </div>
                )
            }
        },
        {
            field: "edit",
            label: "수정",
            render: (value, field, row) => {
                return (
                    <div className="flex justify-center cursor-pointer" onClick={() => {
                        navigate(`/test/article/editor/${row.id}`);
                    }}>
                        <PencilSquareIcon className="size-4" />
                    </div>
                )
            }
        },
        ...columns
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>
                    게시판 보기
                </PageTitle>
                <div className="mb-2">
                    <Input
                        upperLabel="게시판 이름"
                        value={board?.name}
                        readOnly={true}
                        disabled={true}
                    />
                </div>
                <EmptyForm
                    upperLabel={'게시글 관리'}
                >
                    <div className="p-2">
                        <PagingTable
                            refreshKey={refreshKey}
                            fetchData={fetchData}
                            columns={columnsWithActions}
                            isCrudable={true}
                            isEditable={false}
                            onCreate={onCreated}
                            onDelete={onDeleted}
                        />
                    </div>
                </EmptyForm>
                <div className="flex mt-2">
                    <div>
                        <Button
                            onButtonClicked={() => {
                                navigate(`/test/board`);
                            }}
                        >
                            뒤로가기
                        </Button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default BoardViewer;

